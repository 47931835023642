
import {useStore} from "vuex"
import router from "@/router"
import {ref} from "vue"

export default {
	name: "Home",
	setup() {
		const store = useStore()
		const firstName = ref(store.getters["session/getFirstName"])
		const lastName = ref(store.getters["session/getLastName"])
		const organizationName = ref(store.getters["session/getOrganizationName"])

		const profileString = ref(store.getters["profile/getProfileString"])

		function navigateToStart() {
			if (!store.getters["profile/isValid"]) {
				store.dispatch(
					"dialog/setMessage",
					"Invalid Profile. Please update your profile to continue."
				)
				store.dispatch("dialog/setIconType", "has-text-danger")
				store.dispatch("dialog/setIcon", "fa-exclamation-triangle")
				store.dispatch("dialog/setButtonLeftText", "CANCEL")
				store.dispatch("dialog/setButtonLeftFunc", () => {
					store.dispatch("dialog/closeDialog")
				})
				store.dispatch("dialog/setButtonRightText", "EDIT PROFILE")
				store.dispatch("dialog/setButtonRightFunc", () => {
					store.dispatch("dialog/closeDialog")
					router.push("/profile")
				})
				store.dispatch("dialog/setButtonXText", "X")
				store.dispatch("dialog/setButtonXFunc", () => {
					store.dispatch("dialog/closeDialog")
				})
				store.dispatch("dialog/openDialog")

				return
			}

			router.push("/start")
		}

		function navigateToAbout() {
			router.push("/about")
		}

		function navigateToProfile() {
			router.push("/profile")
		}

		function navigateToLogout() {
			store.dispatch("dialog/setMessage", "Do you want to logout?")
			store.dispatch("dialog/setIconType", "has-text-danger")
			store.dispatch("dialog/setIcon", "fa-exclamation-triangle")
			store.dispatch("dialog/setButtonLeftText", "CANCEL")
			store.dispatch("dialog/setButtonLeftFunc", () => {
				store.dispatch("dialog/closeDialog")
			})
			store.dispatch("dialog/setButtonRightText", "LOGOUT")
			store.dispatch("dialog/setButtonRightFunc", () => {
				store.dispatch("dialog/closeDialog")
				router.push("/logout")
			})
			store.dispatch("dialog/setButtonXText", "X")
			store.dispatch("dialog/setButtonXFunc", () => {
				store.dispatch("dialog/closeDialog")
			})
			store.dispatch("dialog/openDialog")
		}

		return {
			firstName,
			lastName,
			profileString,
			organizationName,
			navigateToStart,
			navigateToProfile,
			navigateToAbout,
			navigateToLogout,
		}
	},
}
